import { BaseModel } from "./base.model";

export class Schedule extends BaseModel {
  constructor(
    public active: boolean,
    public title: string,
    public items?: []
  ) {
    super();
  }
}
                         
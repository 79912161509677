import { BaseModel } from "./base.model";

export class ScheduleItem extends BaseModel {
  constructor(
    public start: string,
    public stop: string,
    public title: {[lang:string]: string},
    public description?: {[lang:string]: string},
    public image_url?: string,
    public room_name?: string,
    public room_url?: string,
    public room_target?: string,
  ) {
    super();
  }
}
                         
<template>
  <div>
    <b-row>
      <b-col>
        <h1>Schedule</h1>
      </b-col>
      <b-col cols="3" align-self="center">
        <b-alert
          align-v="center"
          variant="warning"
          :show="saveMessage !== ''"
          >{{ this.saveMessage }}</b-alert
        >
      </b-col>
      <b-col cols="auto">
        <b-button
          class="mb-5 save-button"
          @click="save()"
          :disabled="saveDisabled"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
    <b-form-group :label="$t('title')">
      <b-form-input v-model="schedule.title"></b-form-input>
    </b-form-group>
    <b-button variant="secondary" class="mt-2 mb-2" @click="add">
      {{ $t("addItem") }}
    </b-button>

    <draggable
      class="list-group mb-5"
      tag="ul"
      :list="object"
      v-bind="dragOptions"
      @start="drag = true"
      @end="drag = false"
    >
      <transition-group type="transition" :name="!drag ? 'flip-list' : null">
        <b-card
          class="list-group-item"
          v-for="(item, itemIndex) in schedule.items"
          :key="itemIndex"
          no-body
        >
          <ContentHeader
            v-if="item"
            :object="schedule.items"
            :index="itemIndex"
          />
          <b-collapse
            :id="`accordion-${itemIndex}`"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <b-form-group label="Start">
                <flat-pickr
                  v-model="item.start"
                  :config="flatPickerConfig"
                ></flat-pickr>
              </b-form-group>
              <b-form-group label="End">
                <flat-pickr
                  v-model="item.stop"
                  :config="flatPickerConfig"
                ></flat-pickr>
              </b-form-group>
              <b-tabs content-class="mt-3" nav-class="no-padding">
                <b-tab
                  v-for="(language, langindex) in languages"
                  :key="langindex"
                >
                  <template #title>
                    <country-flag :country="language" size="normal" />
                  </template>
                  <b-form-group :label="$t('title')">
                    <b-form-input v-model="item.title[language]"></b-form-input>
                  </b-form-group>
                  <b-form-group :label="$t('description')">
                    <b-form-input
                      v-model="item.description[language]"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group :label="$t('image')">
                    <ImageUpload
                      :title="$t('bannerImage')"
                      :url="item.image_url"
                      ref="upload"
                      @updateImage="updateItemImage(itemIndex, ...arguments)"
                    />
                  </b-form-group>
                  <!-- <b-form-group :label="$t('buttonText')">
                      <b-form-input
                        v-model="block.button_text[language]"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group :label="$t('buttonLink')">
                      <b-form-input
                        v-model="block.button_link[language]"
                      ></b-form-input>
                    </b-form-group> -->
                  <!-- </div> -->
                </b-tab>
              </b-tabs>
            </b-card-body>
          </b-collapse>
        </b-card>
      </transition-group>
    </draggable>
  </div>
</template>
<script>
import { ScheduleItem } from "@/models/schedule-item.model";
import { Schedule } from "@/models/schedule.model";
import ImageUpload from "@/components/admin/ImageUpload.vue";
import ContentHeader from "@/components/admin/home/ContentHeader.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
    ImageUpload,
    ContentHeader,
    flatPickr
  },
  data() {
    return {
      schedule: "",
      index: "",
      saveDisabled: true,
      saveMessage: "",
      isLoading: false,
      flatPickerConfig: {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
      },
    };
  },
  async created() {
    await this.doFetchAll();

    if (this.$route.query.index) {
      this.schedule = this.schedules[this.$route.query.index];
    } else {
      this.schedule = new Schedule(true, "", [])
      console.log(this.schedule)
      this.add()
    }
    this.saveDisabled = true;
    this.saveMessage = "";
  },
  watch: {
    schedule: {
      handler() {
        this.saveMessage = "You have unsaved changes!";
        this.saveDisabled = false;
      },
      deep: true,
    },
  },
  methods: {
    add: function () {
      this.schedule.items.push(
        new ScheduleItem("", "", {}, {}, "", "", "", "")
      );
    },
    async save() {
      this.isLoading = true;

      if (this.$route.query.index) {
        this.schedules[this.index] = this.schedule;
      } else {
        this.schedules.push(this.schedule)
      }

      await this.$store.dispatch("Schedule/update", this.schedules);
      this.$root.$bvToast.toast("schedule settings saved!", {
        variant: "success",
      });

      this.saveDisabled = true;
      this.saveMessage = "";
      this.isLoading = false;
    },
    updateItemImage(itemIndex, newUrl) {
      this.schedule.items[itemIndex].image_url = newUrl;
    },
    async doFetchAll() {
      this.isLoading = true;
      await this.$store.dispatch("Schedule/fetchAll");
      this.isLoading = false;
    },
  },
  computed: {
    schedules() {
      return this.$store.state.Schedule.all;
    },
    languages() {
      return this.$store.state.Languages.all;
    },
    baseUrl() {
      return window.location.origin;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
};
</script>
<style lang="scss" scoped>
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group-item {
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.list-group-item i {
  cursor: pointer;
}

.button {
  margin-top: 35px;
}
</style>